import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useQueryParam } from 'gatsby-query-params';
import * as webSocketActions from '../../redux/actions/webSocketActions';
import * as serviceAddressActions from '../../redux/actions/serviceAddressActions';
import './index.css';

import CheckoutForm, { defaultUiControl } from './CheckoutForm';

function Checkout({
  actions,
  serviceAddress,
  mode,
  stateList,
  siteConfig,
  productTypeID,
  productTypeName,
  modal
}) {
  const [selectedSection, setSelectedSection] = useState(null);
  const [uiControl, setUiControl] = useState(defaultUiControl);

  useEffect(() => {
    if (!selectedSection && serviceAddress && serviceAddress.Checklist) {
      const serviceSectionID = productTypeID === 6 ? 21 : 20;
      const _section = serviceAddress.Checklist.filter(
        (section) => section.ServiceSectionID === serviceSectionID
      )[0];

      setSelectedSection(_section);
    }
  }, [serviceAddress]);

  return serviceAddress ? (
    <>
      <div
        className={
          'page-container checkout-container' +
          (serviceAddress &&
          serviceAddress.Address &&
          serviceAddress.Address.Address
            ? ' with-address'
            : '') +
          (uiControl.undockSummary ? ' undocked' : '') +
          (productTypeID === 1 ? ' internet' : ' product-type-' + productTypeID)
        }>
        {serviceAddress &&
        serviceAddress.Cart &&
        serviceAddress.Cart.length &&
        selectedSection ? (
          <h1>
            {uiControl.titleOverride
              ? uiControl.titleOverride
              : selectedSection.Title}
          </h1>
        ) : null}

        <CheckoutForm
          productTypeID={productTypeID}
          productTypeName={productTypeName}
          modal={modal}
          uiControl={uiControl}
          setUiControl={setUiControl}
        />
      </div>
    </>
  ) : null;
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress,
    stateList: state.commonData.stateList,
    siteConfig: state.commonData.siteConfig
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch),
      saveOrder: bindActionCreators(serviceAddressActions.saveOrder, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
